@import "../variables";
@import "~bootstrap/scss/mixins/breakpoints";

.page-navigation {
    position: relative;
    margin: 0 -22px;
    z-index: 99;

    .page-navigation-link {
        font-size: 14px;
        text-transform: uppercase;
        text-decoration: none;
        font-weight: bold;
        color: rgba(white, 0.7);
        transform: color 0.25s ease-in-out;
        padding: 12px 24px;

        &:hover {
            text-decoration: underline;
            color: rgba(white, 1);
            transform: color 0.25s ease-in-out;
        }

        &.active {
            text-decoration: underline;
            color: rgba(white, 1);
            transform: color 0.25s ease-in-out;
        }
    }
}

header {
    position: relative;
    overflow: hidden;
    // min-height: 810px;
    margin-top: -108px;
    // background-image: url("../images/v2/Prizific_Site_hero.webp");
    // background-size: contain;
    // background-repeat: no-repeat;
    // background-position: top center;

    .header-logo {
        width: 133px;
    }

    h1 {
        font-size: 1.5rem;
        line-height: 1.4;
        color: $primary;
    }

    p {
        font-size: 1rem;
        line-height: 1.35;
    }

    .hero-text {
        margin-top: -120px;
    }

    @include media-breakpoint-up(sm) {
        h1 {
            font-size: 2rem;
            line-height: 1.4;
        }

        p {
            font-size: 1rem;
            line-height: 1.2;
        }
    }

    @include media-breakpoint-up(md) {
        h1 {
            font-size: 2.25rem;
            line-height: 1.4;
        }

        .hero-text {
            margin-top: -262px;
        }

        .header-logo {
            width: 233px;
        }
    }

    @include media-breakpoint-up(lg) {
        h1 {
            font-size: 2.5rem;
            line-height: 1.4;
        }

        p {
            font-size: 1.15rem;
            line-height: 1.35;
        }
    }

    @include media-breakpoint-up(xxl) {
        h1 {
            font-size: 50px;
            line-height: 1.4;
        }

        p {
            font-size: 20px;
            line-height: 1.35;
            padding: 0 271px;
        }
    }
}

h2 {
    position: relative;
    font-size: 1.5rem;
    line-height: 1;

    @include media-breakpoint-up(sm) {
        font-size: 1.65rem;
    }
    @include media-breakpoint-up(md) {
        font-size: 1.75rem;
    }
    @include media-breakpoint-up(lg) {
        font-size: 1.85rem;
    }
    @include media-breakpoint-up(xxl) {
        font-size: 2rem;
    }
}

.z-index-2 {
    z-index: 2;
}

.product-texts {
    h3 {
        color: $primary;
        font-size: 20px;
        font-weight: 800;
    }

    &.left {
        padding-right: 0;
    }

    &.right {
        padding-left: 0;
    }

    @include media-breakpoint-up(md) {
        &.left {
            padding-right: 4rem;
        }

        &.right {
            padding-left: 4rem;
        }
    }

    @include media-breakpoint-up(lg) {
        h3 {
            font-size: 32px;
            margin-bottom: 32px;
        }
    }

    @include media-breakpoint-up(xxl) {
    }
}

.product-image-wrapper {
    position: relative;

    &::before {
        position: absolute;
        content: "";
        bottom: -2rem;
        width: 75%;
        height: 75%;
        z-index: -1;
        background-color: $secondary;
    }

    &.decoration-logo {
        &::before {
            position: absolute;
            content: "";
            top: 50%;
            left: 50%;
            width: 499px;
            height: 499px;
            z-index: -1;
            background: url("../images/v2/Prizific_circle.svg");
            transform: translate(-50%, -50%);
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;

            @include media-breakpoint-up(md) {
                top: 50%;
                left: 61.5%;
                width: 708px;
                height: 708px;
            }
        }
    }

    &.decoration-logo-rotated {
        &::before {
            position: absolute;
            content: "";
            top: 50%;
            left: 50%;
            width: 399px;
            height: 399px;
            z-index: -1;
            background: url("../images/v2/Prizific_circle_rotated.svg");
            transform: translate(-50%, -50%);
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;

            @include media-breakpoint-up(md) {
                top: 50%;
                left: 61.5%;
                width: 708px;
                height: 708px;
            }
        }
    }

    &.decoration-none {
        &::before {
            display: none;
        }
    }

    &.decoration-left {
        &::before {
            position: absolute;
            content: "";
            left: -2rem;
        }
    }

    &.decoration-right {
        &::before {
            position: absolute;
            content: "";
            right: -2rem;
        }
    }
}

#they-said {
    margin-top: -128px;
}

.blockquote {
    position: relative;
    font-size: 20px;
    line-height: 1.2;
    font-weight: bold;
    text-align: center;
    padding: 0 4rem 0 4rem;

    &::before {
        position: absolute;
        content: "";
        background-size: 100%;
        background-repeat: no-repeat;
        background-image: url("../images/v2/quote.webp");
        width: 32px;
        height: 32px;
        left: 0;
    }

    &::after {
        position: absolute;
        content: "";
        background-size: 100%;
        background-repeat: no-repeat;
        background-image: url("../images/v2/quote.webp");
        width: 32px;
        height: 32px;
        right: 0;
    }

    @include media-breakpoint-up(md) {
        padding: 0 10rem 0 10rem;
        font-size: 32px;

        &::before {
            width: 84px;
            height: 64px;
        }
        &::after {
            width: 84px;
            height: 64px;
        }
    }
}

.swiper-wrapper {
    align-items: center;
}

.blockquote-name {
    font-size: 16px;
    font-weight: 600;

    @include media-breakpoint-up(md) {
        font-size: 20px;
    }
}

.blockquote-schedule {
    font-size: 12px;
    opacity: 0.5;

    @include media-breakpoint-up(md) {
        font-size: 14px;
    }
}

#benefits {
    position: relative;
    background-position: center;
    background-size: 200%;
    background-repeat: no-repeat;
    background-image: url("../images/v2/stars.webp");

    @include media-breakpoint-up(md) {
        background-position: 0% 130%;
        background-size: contain;
    }
}

.benefits-list-item {
    position: relative;
    margin-top: 3rem;
    margin-bottom: 3rem;
    padding-right: 2rem;
    margin-left: 2rem;
    font-size: 20px;

    h3 {
        color: $white;
        font-weight: bold;
        font-size: 26px;
        margin-bottom: 24px;
    }

    .benefits-list-item-number {
        position: absolute;
        width: 100%;
        height: 100%;
        font-size: 240px;
        top: 50%;
        left: 36%;
        line-height: 0.5;
        color: #f8fbf4;
        z-index: -1;
        opacity: 0;
        font-weight: bold;
        transform: translate(-50%, -50%);
        transition: opacity 0.25s ease;
        text-shadow: #f8fbf4 0 0 32px;

        &.fade {
            opacity: 0.3;
            transition: opacity 0.25s ease;
        }
    }

    @include media-breakpoint-up(md) {
        padding-right: 4rem;
        margin-left: 0;
    }
}

.for-agents {
    position: relative;
    color: white;
    font-size: 20px;
    line-height: 1.2 !important;

    h2 {
        font-size: 24px;
        font-weight: 800;
    }

    .for-agents-wrapper {
        position: relative;
        z-index: 1;

        .btn-outline-light {
            border-width: 2px;
            font-weight: bold;
        }
    }

    .for-agents-image {
        position: absolute;
        max-width: 100%;
        width: 520px;
        height: auto;
        top: -60%;
        right: 0;
        transform: translateY(-50%);
    }

    .for-agents-box {
        border-radius: 30px;
        padding: 64px 128px;
    }

    @include media-breakpoint-up(md) {
        font-size: 20px;

        h2 {
            font-size: 40px;
            font-weight: 800;
        }

        .for-agents-image {
            top: 30%;
        }
    }
}

.card {
    background-color: initial;

    .card-icon {
        color: $primary;

        .material-icons-outlined,
        .material-icons {
            font-size: 2.5rem;
            line-height: 1.5;
        }
    }

    .card-title {
        font-size: 20px;
        font-weight: bold;
        margin-top: 16px;
    }

    .card-content {
        font-size: 16px;
    }
}

.contact {
    border-radius: 30px;

    .btn-primary {
        position: relative;
        z-index: 2;
        font-size: 20px;
        font-weight: 800;
    }

    img {
        position: absolute;
        width: 363px;
        height: 363px;
        right: 50%;
        top: 50%;
        transform: translateX(50%);

        @include media-breakpoint-up(md) {
            width: 434px;
            height: 434px;
            right: calc(-434px / 2);
            transform: translateY(-50%);
        }
    }
}

.triangle-contact {
    position: relative;

    &:after {
        pointer-events: none;
        position: absolute;
        content: "";
        width: 390px;
        height: 252px;
        bottom: 0;
        right: -70px;
        z-index: 0;
        background-position: center;
        background-size: 100%;
        background-repeat: no-repeat;
        background-image: url("../images/contact.svg");

        @include media-breakpoint-up(sm) {
            width: 358px;
            height: 425px;
            bottom: 0;
        }
        @include media-breakpoint-up(md) {
            bottom: -75px;
            width: 425px;
        }
        @include media-breakpoint-up(lg) {
            width: 525px;
            bottom: -43px;
        }
        @include media-breakpoint-up(xl) {
            width: 658px;
            bottom: 0;
        }
        @include media-breakpoint-up(xxl) {
        }
    }
}

.triangle-bright {
    position: relative;

    &:before {
        pointer-events: none;
        position: absolute;
        content: "";
        width: 465px;
        height: 234px;
        top: 70px;
        left: 32px;
        z-index: 0;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("../images/triangle_bright.svg");
    }
}

.contact-background {
    position: relative;
    // background-position: center;
    // background-size: cover;
    // background-repeat: no-repeat;
    // background-image: url("../images/footer-bg.png");
}

.btn-text {
    border: 0;
    font-size: 16px;
    padding: 8px 24px;
    font-weight: 800;
    color: $primary;
    font-weight: bold;

    &:hover {
        color: $primary;
    }

    &:focus {
        color: $primary;
    }

    &:active {
        border: 0;

        &:focus {
            color: $primary;
        }
    }

    @include media-breakpoint-up(md) {
        font-size: 20px;
        padding: 16px 24px;
    }
}

.btn-outline-primary {
    color: $primary;
    font-size: 20px;
    border-width: 2px;
    font-weight: 800;
    line-height: 27px;

    &:hover {
        color: $black;
    }
}

.bg-secondary-gradient {
    background: linear-gradient(
        0deg,
        transparent 0%,
        #166681 44%,
        transparent 100%
    );
}

.swipe-image {
    animation: swipeAnimation 1.5s infinite ease-in-out;
}

@keyframes swipeAnimation {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(20px);
    }
    100% {
        transform: translateX(0);
    }
}
